@import '../styles/Mixins.scss';
@import '../styles/Variables.scss';

.footer-component {
    
    width: 100%;
    background-color: $primary-color;
    @include centered-vertical-list;
    height: 100px;
    gap: 10px;
    align-items: center;

    .contact-link {
        text-align: center;
        color: $secondary-color;
    }

    .social-media-icons {
        @include centered-horizontal-list;
        justify-content: center;
        gap: 10px;
    }

    .copyright {
        color: $secondary-color;
    }
}