@mixin vertical-list {
    display: flex;
    flex-direction: column;
}

@mixin centered-vertical-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@mixin horizontal-list {
    display: flex;
}

@mixin centered-horizontal-list {
    align-items: center;
    display: flex;
}