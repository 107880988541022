@import '../styles/Mixins.scss';
@import '../styles/Variables.scss';

.create-account-page {
    @include centered-vertical-list;
    align-items: center;
    margin-top: max(20vh, 70px);
    margin-bottom: 80px;
    gap: 20px;

    .title {
        position: absolute;
        top: 30px;
    }
    
    .input-box {
        width: 300px;
        margin: 10px;
    
        label {
            font-family: $font;
        }
    
        input {
            color: $primary-color; // Color of text inside box
            font-family: $font;
            border-color: initial; // Set the initial border color
    
            &:focus {
                border-color: $primary-color; // Set the desired border color when focused
            }
        }

        & label.Mui-focused {
        color: $primary-color;
        }
    
        & .MuiInput-underline:after {
        border-bottom-color: $primary-color;
        }

        // Error text font
        & .MuiFormHelperText-root {
          font-family: $font;
        }
    }

    .over13 {
        @include centered-horizontal-list;
        
        .over13-checkbox {
            &.Mui-checked {
                color: $primary-color;
            }
        }
        .over13-text {
            margin-right: 10px;
        }
    }

    .create-account-button {
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 150px;
        height: 40px;
        border: none;
        background-color: $primary-color;
        transition-property: background-color;
        transition-duration: .2s;
    }
    .create-account-button:hover {
        background-color: lighten($primary-color, 30%);
    }
    .create-account-button:active {
        background-color: $primary-color;
    }
    .create-account-button-text {
        color: $secondary-color;
    }

    .create-account-error {
        color: $error-color;
        font-size: smaller;
        text-align: center;
    }

    .login-suggestion {
        margin-top: 20px;

        .login-suggestion-text {
            color: $primary-color;
            transition-property: color;
            transition-duration: .2s;
        }
        .login-suggestion-text:hover {
            color: lighten($primary-color, 30%);
        }
    }
}