@import '../styles/Mixins.scss';
@import '../styles/Variables.scss';

.products-page {
    transition-duration: .4s;
    width: min(90vw, 800px);
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
    align-items: center;
    margin-top: 40px;

    // Make page fade in on load
    opacity: 0;
    animation: fadeIn .3s ease-out forwards;
    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }

    .product-grid {    
        // display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        // column-gap: 8px;
        // row-gap: 20px;
        display: flex;
        flex-direction: column;
        gap: 40px;

        .product {
            width: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2px;
            position: relative;

            .product-image-front {
                opacity: 1;
                transition: opacity .5s ease-in-out;
                -moz-transition: opacity .5s ease-in-out;
                -webkit-transition: opacity .5s ease-in-out;
            }

            .product-image-front:hover {
                opacity: 0;
                transition: opacity .5s ease-in-out;
                -moz-transition: opacity .5s ease-in-out;
                -webkit-transition: opacity .5s ease-in-out;
            }

            .product-image-back {
                position: absolute;
                opacity: 0;
                transition: opacity .5s ease-in-out;
                -moz-transition: opacity .5s ease-in-out;
                -webkit-transition: opacity .5s ease-in-out;
            }

            .product-image-back:hover {
                position: absolute;
                opacity: 1;
                transition: opacity .5s ease-in-out;
                -moz-transition: opacity .5s ease-in-out;
                -webkit-transition: opacity .5s ease-in-out;
            }

            // .soldout-badge {
            //     box-shadow: 0 0 0.3em;
            //     background-color: #f74545;
            //     height: 30px;
            //     width: 100px;
            //     position: absolute;
            //     right: 0;
            //     margin-top: 5%;
                
            //     .soldout-text {
            //         color: white;
            //         font-size: 12px;
            //         text-align: center;
            //         margin-top: 5%;
            //     }
            // }
    
            .product-text {
                margin-top: 8px;
                text-align: center;
                font-size: 17px;
            }
        }
    }

    .no-products-text {
        text-align: center;
        margin: auto;
    }
}

@media screen and (max-width: 768px) {

    .products-page {

    
        .product-grid {    

    
            .product {
                width: 300px;
            }
        }
    }

}