@import '../styles/Mixins.scss';
@import '../styles/Variables.scss';


.header-component {
    @include centered-vertical-list;
    align-items: center;
    margin-top: 30px;
    gap: 20px;
    
    .upper-wrapper {
        @include centered-horizontal-list;

        .title {
            text-align: center;
            text-decoration: none;
        }

        .icons {
            @include centered-horizontal-list;
            position: absolute;
            right: 30px;
            gap: 20px;
            
            .account-button {
                color: $primary-color;
            }
    
            .cart-button {
                position: relative;
    
                .badge {
                    font-size: 12px;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: $notification-color;
                    color: $secondary-color;
                    position: absolute;
                    right: -10px;
                    top: -11px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    // The bottom row of links, should only be visible on larger screens
    .desktop-nav {
        @include horizontal-list;
        gap: 50px;
        color: $primary-color;
    
        .header-link {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative; /* Add this line */
    
            .header-underline {
                position: absolute; /* Add this line */
                bottom: 0; /* Add this line */
                left: 50%; /* Add this line */
                transform: translateX(-50%); /* Add this line */
                width: 0%;
                height: 2px;
                background-color: black;
                transition: width 0.3s ease; /* Combine transition properties */
            }
    
            &:hover .header-underline { /* Modify this line */
                width: 100%;
            }
        }
    }
    
    
}

// For smaller screens, reduce font size, and hide desktop links/icons
@media screen and (max-width: 768px) {
    .header-component {
        .upper-wrapper {
            .title {
                font-size: 70%;
            }
            
            .icons {
                .account-button,
                .cart-button {
                    display: none;
                }
            }
        }

        .desktop-nav {
            display: none;
        }   

    }
}