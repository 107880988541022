@import '../styles/Mixins.scss';
@import '../styles/Variables.scss';

.product-page {
    margin: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;

    // Make page fade in on load
    opacity: 0;
    animation: fadeIn .3s ease-out forwards;
    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }

    .product-images {
        width: 500px;
        max-width: 90vw;

        .featured-image {
            width: 500px;
            max-width: 90vw;
        }

        .selector {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            row-gap: max(1%, 5px);
            column-gap: 1%;
            
            .selected-preview-image {
                box-shadow: 0 0 0 2px rgb(0, 0, 0);
                width: 96px;
                max-width: 17.2vw;
            }

            .preview-image {
                width: 96px;
                max-width: 17.2vw;
            }
        }
    }

    .product-text {

        flex: 1 1 300px; /* or 1 0 100px, and no more need in min-width at all */
        max-width: 500px;
        min-width: 200px;

        @include vertical-list;
        gap: 1rem;

        .material-selector {
            display: flex;
            gap: 5px;

            .material-image {
                border-radius: 50%;
                cursor: pointer;
            }

            .material-image-selected {
                box-shadow: 0 0 0 2px rgb(0, 0, 0);
                border-radius: 50%;
                cursor: pointer;
            }

        }

        .add-to-cart {
            margin-top: 40px;
            display: flex;
            gap: 5px;
            align-items: center;

            .add-to-cart-button {
                cursor: pointer;
                width: 150px;
                height: 40px;
                border: none;
                background-color: $primary-color;
                transition-property: background-color;
                transition-duration: .2s;
            }
            .add-to-cart-button:hover {
                background-color: lighten($primary-color, 30%);
            }
            .add-to-cart-button:active {
                background-color: $primary-color;
            }
        }

        .product-detail-with-icon {
            @include centered-horizontal-list;
            color: $primary-color;
            gap: 10px;
        }
    }
}