@import '../styles/Mixins.scss';
@import '../styles/Variables.scss';

.landing-page {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    position: relative;
    background-color: $secondary-color;

    .wallet-box-container {
        position: relative;
        display: flex;
        align-items: center;
        z-index: 0;
        padding-left: 30px;
        padding-right: 30px;
        padding: 30px;
        max-width: 1000px;
        align-items: center;
        justify-content: center;

        .tagline {
            width: 20%;
            text-align: center;
            font-size: 2rem;
        }

        .wallet {
           width: 80%;
        }
    }

    .includes-bar {
        height: 150px;
        width: 100vw;
        background-color: $primary-color;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        z-index: 5;

        .includes-caption {
            color: $secondary-color;
        }

        .down-arrow {
            color: $secondary-color;
            scale: 300%;
            animation: bounce 1s infinite alternate;
            transition-property: opacity;
            transition-duration: .5s;
            opacity: 1;
        }

        .down-arrow.invisible {
            opacity: 0;
        }

        @keyframes bounce {
            0% {
                transform: translateY(5px);
            }
            100% {
                transform: translateY(-5px);
            }
        }
    }

    .includes {
        display: flex;
        z-index: 1;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 100px;
        background-color: #f4f3f1;
        width: 100%;

        .box {
            position: relative;
            width: 100%;

            .box-closed {
                width: 100%;
                transition-property: opacity;
                transition-duration: 1s;
            }

            .box-open {
                position: absolute;
                left: 0;
                width: 100%;
                opacity: 0;
                transition-property: opacity;
                transition-duration: 1s;
            }

            .box-open.hidden {
                opacity: 0;
            }

            .box-open.show {
                opacity: 1;
            }

            .box-closed.hidden {
                opacity: 0;
            }

            .box-closed.show {
                opacity: 1;
            }

        }

        .includes-grid {

            display: grid;
            grid-template-columns: repeat(3, 1fr); /* 3 columns */
            grid-template-rows: repeat(2, auto); /* 2 rows */
            gap: 40px; /* Gap between grid items */
            
            .item {
                height: 200px;
                display: flex;
                flex-direction: column;
                align-items: center;
    
                .item-photo {
                    height: 200px;
                }
    
                .item-name {
                    font-size: small;
                }
            }

            .item.hidden {
                opacity: 0;
                transition: opacity 1s;
            }

            .item.show {
                opacity: 1;
                transition: opacity 1s;
            }
            .item.show:nth-child(1) {
                transition-delay: 800ms;
            }
            .item.show:nth-child(2) {
                transition-delay: 1000ms;
            }
            
            .item.show:nth-child(3) {
                transition-delay: 1200ms;
            }
            .item.show:nth-child(4) {
                transition-delay: 1400ms;
            }
            .item.show:nth-child(5) {
                transition-delay: 1600ms;
            }
            .item.show:nth-child(6) {
                transition-delay: 1800ms;
            }
            .item.show:nth-child(7) {
                transition-delay: 2000ms;
            }

        }
    }

    .video-bar {
        height: 150px;
        width: 100vw;
        background-color: $primary-color;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        z-index: 5;

        .video-caption {
            color: $secondary-color;
            text-align: center;
        }
    }

    .video {
        width: 100%;
        left: 0;
        z-index: 0;
    }

    .end-bar {
        height: 150px;
        width: 100vw;
        background-color: $primary-color;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        z-index: 5;

        .end-caption {
            color: $secondary-color;
            text-align: center;
        }
    }

    .view-products-button-container {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;

        .view-products-button {
            color: $secondary-color;
            background-color: $primary-color;
            height: 3rem;
            width: 8rem;
            font-size: 10px;
            transition: none;
            transition-property: background-color;
            transition-duration: .4s;
            cursor: pointer;
            position: absolute;
            bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .view-products-text {
                color: $secondary-color;
                font-size: small;
            }
    
        }
    
        .view-products-button:hover {
            background-color: lighten($primary-color, 30%);
    
        }
    
        .view-products-button:active {
            background-color: $primary-color;
        }

        

    }

    .final-product {
        width: 100%;
        transition-property: opacity;
        transition-duration: 1s;
    }

    .final-product.hidden {
        opacity: 0;
    }

    .final-product.show{
        opacity: 1;
    }


}

@media screen and (max-width: 768px) {

    .landing-page {
        .wallet-box-container {
            display: flex;
            flex-direction: column-reverse;
            gap: 10px;
            min-height: 400px;
            align-items: center;
            justify-content: center;

            .tagline {
                font-size: 2rem;
                width:fit-content;
            }

            .wallet {
                width: 100%;
            }
        }

        .includes-bar {
            height: 100px;
            
            .down-arrow {
                color: $secondary-color;
                scale: 200%;
            }
        }

        .includes {
    
            .box {
                position: relative;
            }
    
            .includes-grid {
                gap: 30px; /* Gap between grid items */              
                
                grid-template-columns: repeat(2, 1fr); /* 3 columns */
                grid-template-rows: repeat(3, auto); /* 2 rows */

                .item {
                    height: 100px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
        
                    .item-photo {
                        height: 100px;
                    }
        
                    .item-name {
                        font-size: small;
                    }
                }
            }
        }

        .video-bar {
            height: 100px;
        }

        .end-bar {
            height: 100px;
        }


        .view-products-button-container {
        

            .view-products-button {
                height: 25px;
                font-size: small;
                width: 90px;
            }
        }

    }
}
