@import './styles/Variables.scss';

// Globals

.link {
  text-decoration: none;
}

// .disable-scroll {
//   overflow: hidden;
// }

body {
  margin: 0;
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary-color;
  background-color: $secondary-color;
  overflow-x: hidden;
}

select {
  font-family: $font;
}

input {
  font-family: $font;
}

h4, h3, h2, h1, p {
  margin: 0;
  text-align: justify;
  color: $primary-color;
}

a {
  color: $secondary-color;
}        

button {
  font-family: $font;
  cursor: pointer;
  border: none;
  color: $primary-color;
  background-color: $secondary-color;

  p {
    font-family: $font;
    text-align: center;
    color: $secondary-color
  }
}

// Custom Scrollbar

html::-webkit-scrollbar {
  width: 1vw;
}

html::-webkit-scrollbar-thumb {
  background-color: $primary-color;
}

html::-webkit-scrollbar-track {
  background-color: $secondary-color;
}

html {
  scrollbar-color: $primary-color, $secondary-color;
}

// Screen Blur

.screen-blur {
  z-index: 9;
  background-color: $primary-color;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition-property: opacity;
  transition-duration: .4s;
  pointer-events: none;
}

.screen-blur.visible {
  opacity: .5;
  pointer-events: auto;
}