@import '../styles/Mixins.scss';
@import '../styles/Variables.scss';

.about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    .about-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding-top: 20px;
        width: min(500px, 80vw);
        color: $primary-color;
        

        .who-we-are {
            align-items: center;
        }
    
        .intro {
            text-align: justify
        }
    
        .section-header {
            @include centered-horizontal-list;
            gap: 10px;
            align-self: center;
        }
    
        .section-desc {
            text-align: justify;
        }
    }

    .background-image-container {
        display: flex;
        flex-direction: row-reverse;
        width: 100vw;
        // max-width: 900px;
        
        .background-image {
            z-index: -5;
            width: 100vw;
        }
    }
}