@import '../styles/Mixins.scss';
@import '../styles/Variables.scss';

.not-found-page {
    @include centered-vertical-list;
    align-items: center;
    gap: 30px;
    margin-top: 200px;
    margin-bottom: 200px;
    
    .not-found-icon {
        scale: 200%;
        color: $primary-color;
    }

    .not-found-text {
        color: $primary-color;
    }
}