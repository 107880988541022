@import '../styles/Mixins.scss';
@import '../styles/Variables.scss';

.hamburger-button {
    display: none;
    z-index: 1;
}

.mobile-nav {
    z-index: 10;
    display: fixed;
    position: fixed;
    padding: 10px;
    right: -200px;
    top: 20px;
    width: 200px;
    background-color: $secondary-color;
    transition-property: right, opacity, position;
    transition-duration: .4s;
    border-radius: 5px;
    opacity: 0%;

    .mobile-nav-item {
        @include centered-horizontal-list;
        gap: 10px;
        color: inherit;
        text-decoration: none;
        color: $primary-color;
        padding-left: 6%;
        padding-top: 10px;
        padding-bottom: 10px;
        
        .mobile-nav-item-icon {
            scale: 130%;
        }
    }
}

.mobile-nav.open {
    opacity: 100%;
    right: 20px;
}

// For smaller screens, reduce gap and font-size
@media screen and (max-width: 768px) {
  
    .hamburger-button {
        color: $primary-color;
        transform: scale(1.4);
        display: block;
        margin-top: 7px;
        width: 35px;
        height: 35px;
        cursor: pointer;
    }
    
    .mobile-nav {
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 100%;
    }
}
