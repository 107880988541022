@import '../styles/Mixins.scss';
@import '../styles/Variables.scss';

.cart-component {
    width: 450px;
    right: -450px;
    z-index: 12; // Make cart appear above hamburger
    opacity: 0%;
    background-color: $secondary-color;
    position: fixed;
    
    @include vertical-list;
    gap: 10px;
    padding: 10px;
    padding-left: 20px;
    border-radius: 5px;
    top: 20px;

    // Transition
    transition-property: right .4s, opacity .4s, height .4s;
    transition-duration: .4s;

    // Custom scrollbar
    overflow: auto;
    max-height: 98%;
    &::-webkit-scrollbar {width: 1vw;}
    &::-webkit-scrollbar-thumb {background-color: $primary-color;}
    &::-webkit-scrollbar-track {background-color: $secondary-color;}
    scrollbar-width: thin;
    scrollbar-color: $primary-color, $secondary-color;
      
    .cart-title {
        @include centered-horizontal-list;
        position: relative;
        justify-content: center;
        margin: 5%;
    }

    .exit-cart-button {
        position: absolute;
        top: 10px;
        left: 0;
        scale: 120%;
    }

    .cart-item {
        display: flex;
        gap: 2%;
        position: relative;
        padding-right: 8%;

        .cart-item-image {
            width: 100px;
            height: 100px;
        }

        .cart-item-details {
            @include vertical-list;
            justify-content: space-around;
            text-align: left;

            .qty-selector {
                @include centered-horizontal-list;
                
                .change-qty-icon {
                    margin-top: 1px;
                    transition-property: color, background-color;
                    transition-duration: .2s;
                }

                .cart-qty {
                    min-width: 20px;
                    text-align: center;
                }

                .change-qty-icon:hover {
                    margin-top: 1px;
                    color: $secondary-color;
                    background-color: $primary-color;
                }
            }
        }

        .cart-item-price {
            position: absolute;
            bottom: 15%;
            right: 0;
            margin-right: 8%
        }
    }

    .stock-alert {
        // Make alert fade in and out
        opacity: 0;
        animation: fadeIn .3s ease-out forwards;
        @keyframes fadeIn {
            to {
                opacity: 1;
            }
        }
        
        display: flex;
        align-items: center;
        margin-top: 10px;

        .stock-alert-text {
            font-size: small;
        }
    }

    .subtotal {
        margin-top: 30px;
        text-align: right;
        margin-right: 8%;
    }

    .cart-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 60px;
        margin-top: 20px;

        .cart-empty-icon {
            scale: 400%;
        }
    }

    .checkout {
        @include centered-vertical-list;
        align-items: center;
        margin: 20px;

        .checkout-button {
            color: $secondary-color;
            background-color: $primary-color;
            height: 50px;
            width: 200px;
            font-size: 18px;
            transition: none;
            transition-property: background-color;
            transition-duration: .4s;
        }
    
        .checkout-button:hover {
            background-color: lighten($primary-color, 30%);
    
        }
    
        .checkout-button:active {
            background-color: $primary-color;
        }
    }

    .checkout-error {
        // Make alert fade in and out
        opacity: 0;
        animation: fadeIn .3s ease-out forwards;
        @keyframes fadeIn {
            to {
                opacity: 1;
            }
        }
        
        display: flex;
        align-items: center;

        .checkout-error-text {
            font-size: small;
        }
    }

}

.cart-component.open {
    right: 20px;
    opacity: 100%;
}

// For smaller screens, cart takes up whole screen
@media screen and (max-width: 768px) {
    .cart-component {
        transition-property: right, opacity, left;
        left: calc(20px + 100vw);
        right: calc(-20px + -100vw);
        width: auto;
    }
    
    .cart-component.open {
        left: 20px;
        right: 20px;
    }

    .cart-item-details {
        font-size: 90%;
    }
}