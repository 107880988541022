@import '../styles/Mixins.scss';
@import '../styles/Variables.scss';

.account-page {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: min(80vw, 800px);
    gap: 50px;

    // Make page fade in on load
    opacity: 0;
    animation: fadeIn .3s ease-out forwards;
    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }

    .profile-header {
        border-color: $primary-color;
        border-width: 0px 0px 2px 0px;
        border-style: solid;
        width: fit-content;
    }

    .profile-content {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        
        .user-details {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }

    .orders-header {
        border-color: $primary-color;
        border-width: 0px 0px 2px 0px;
        border-style: solid;
        width: fit-content;
    }

    .orders-content {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .order {
            border-color: $primary-color;
            border-width: 1.5px 1.5px 1.5px 1.5px;
            border-style: solid;
            padding: 14px;
            // padding-left: 14px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 5px;
            
            .order-left {
                display: flex;
                flex-direction: column;
                gap: 2px;

                .order-number {
                    margin-left: 4px;
                }
                
                .view-order-button {
                    display: flex;
                    gap: 2px;
                    align-items: center;
                    
                    .view-order-button-text {
                        color: $primary-color;
                        font-size: smaller;
                        text-align: left;
                        color: green;
                    }

                    .right-arrow-icon {
                        scale: 80%;
                        color:green;
                    }
                }
            }

            .order-middle {
                display: flex;
                gap: 8px;
                align-items: center;

                .order-ship-status-circle {
                    width: 12px;
                    height: 12px;
                    background: red;
                    border-radius: 100%;
                }

                .order-ship-status-text {
                    font-size: small;
                }
            }

            .order-right {
                font-size: medium;
            }
        }

        .no-orders-container {
            display: flex;
            align-items: center;
            padding: 20px;
            
            .no-orders-text {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .messages-header {
        border-color: $primary-color;
        border-width: 0px 0px 2px 0px;
        border-style: solid;
        width: fit-content;
    }

    .messages-content {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .message {
            border-color: $primary-color;
            border-width: 1.5px 1.5px 1.5px 1.5px;
            border-style: solid;
            padding: 14px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start; /* Align children to the start */
            gap: 5px;
        
            .message-top {
                display: flex;
                flex-direction: column;
                gap: 3px;
        
                .message-top-text {
                    font-size: small;
                }
            }
            .message-bottom {
                font-size: small;
            }
        }
        

        .no-messages-container {
            display: flex;
            align-items: center;
            padding: 20px;
            
            .no-messages-text {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }

    }

    .logout-button-container {
        display: flex;
        justify-content: center;
        padding: 20px;

        .logout-button {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 40px;
            background-color: $primary-color;
            transition-property: background-color;
            transition-duration: .2s;
            
            &:hover {
                background-color: lighten($primary-color, 30%);
            }
    
            &:active {
                background-color: $primary-color;
            }
    
            .logout-button-text {
                color: $secondary-color;
            }
        }
    }
}

.error-text {
    color: red;
    margin-top: 30vh;
}

// For smaller screens, reduce font-size
@media screen and (max-width: 768px) {
    .account-page {
        .orders-content {

            .order {
                padding-left: 5px;

                .order-left {
                    margin-top: 5px;

                    .order-number {
                        font-size: x-small;
                    }

                    .view-order-button {
                        .view-order-button-text {
                            font-size: xx-small;
                        }

                        .right-arrow-icon {
                            scale: 60%;
                        }
                    }
                }

                .order-right {
                    .ordered-item-image {
                        width: 45px;
                        height: 45px;
                    }

                    .fourth-item-image {
                        width:25px;
                        height: 45px;
                    }

                    .fourth-item-image-overlay {
                        position: absolute;
                        right: 0;
                        background: linear-gradient(to right, rgba(255, 255, 255, 0), $secondary-color);
                        width:45px;
                        height: 45px;
                    }
                }

                .order-middle {
                    .order-ship-status-text {
                        font-size: xx-small;
                    }

                    .order-ship-status-circle {
                        height: 5px;
                        width: 5px;
                    }
                }

                .order-right {
                    font-size: x-small;
                }
            }
        }
    }

    // If screen super small, hide shipping status in order page
    @media screen and (max-width: 450px) {
        .account-page {
            .orders-content {
                .order {
                    .order-middle {
                        display: none;
                    }
                }
            }
        }
    }
}
