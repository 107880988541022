@import '../styles/Mixins.scss';
@import '../styles/Variables.scss';

.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    gap: 20px;
    max-width: 80vw;
    margin-bottom: 40px;

    .contact-header {
        gap: 10px;
        display: flex;
        align-items: center;
    }

    .contact-info {
        text-align: block;
        width: 500px;
        max-width: 80vw;
    }

    .input-boxes {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text-box {
            width: 500px;
            max-width: 80vw;
            margin: 10px;
        
            label {
                font-family: $font;
            }
        
            input, textarea { /* Update the selector to include textarea */
                color: $primary-color;
                font-family: $font;
                border-color: initial;
        
                &:focus {
                    border-color: $primary-color;
                }
            }
        
            & label.Mui-focused {
                color: $primary-color;
            }
        
            & .MuiInput-underline:after {
                border-bottom-color: $primary-color;
            }
        
            & .MuiFormHelperText-root {
                font-family: $font;
            }
        }
        .send-button {
            cursor: pointer;
            margin: 20px;
    
            display: flex;
            align-items: center;
            justify-content: center;
    
            width: 150px;
            height: 40px;
            border: none;
            background-color: $primary-color;
            transition-property: background-color;
            transition-duration: .2s;
        }
        .send-button:hover {
            background-color: lighten($primary-color, 30%);
        }
        .send-button:active {
            background-color: $primary-color;
        }
        .send-button-text {
            color: $secondary-color;
        }

        .message-success-text {
            color: $success-color;
        }

        .message-error-text {
            color: $error-color
        }
    }
}